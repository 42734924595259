import { Header } from 'header'
import { Terms as Component, Seo } from 'ui-components'

const Terms = () => {
  return (
    <>
      <Seo
        title='Terms and Conditions'
        description='Mapstack is a free-to-use online mapping tool. Use it to create interactive maps, visualisations and info graphics.'
      />
      <Component header={Header} />
    </>
  )
}

export { Terms }
