import { dehydrate } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getProps } from 'ssr/getProps'
import { homePageView } from 'tag-manager/page_view'
import { WORKSPACE_TAB } from 'ui-components'
import { Home as App } from 'web-pages'

import {
  getCurrentWorkspaceFromRequestCookies,
  getSerializedCurrentWorkspaceCookieForResponseHeader,
  getWorkspaceLink
} from 'utils'

const Home = (props) => {
  useEffect(() => {
    homePageView()
  }, [])

  return <App />
}

export default Home

export const getServerSideProps = getProps((context, client, state) => {
  const user = state?.user

  if (!user) {
    return {
      props: {
        dehydratedState: dehydrate(client)
      }
    }
  }

  const currentWorkspaceCookie = getCurrentWorkspaceFromRequestCookies(
    context.req.cookies
  )

  if (currentWorkspaceCookie) {
    const { id, name } = currentWorkspaceCookie

    return {
      redirect: {
        destination: getWorkspaceLink({
          workspaceId: id,
          workspaceName: name,
          tab: WORKSPACE_TAB.MAPS
        }),
        permanent: false
      }
    }
  }

  const workspace = user?.membershipCollection?.items.find(
    (i) => i.workspace.id === user?.defaultWorkspaceId
  )?.workspace

  if (!workspace) return { props: {} }

  const { id, name } = workspace

  context.res.setHeader(
    'Set-Cookie',
    getSerializedCurrentWorkspaceCookieForResponseHeader({ id, name })
  )

  return {
    redirect: {
      destination: getWorkspaceLink({
        workspaceId: id,
        workspaceName: name,
        tab: WORKSPACE_TAB.MAPS
      }),
      permanent: false
    }
  }
})
