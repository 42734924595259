import { Header } from 'header'
import { Pricing as Layout, Seo } from 'ui-components'

export function Pricing() {
  return (
    <>
      <Seo
        title='Mapstack Pricing: Find the Perfect Plan for Your Interactive Mapping Needs'
        description="Explore Mapstack's pricing plans tailored for individuals, teams, and enterprises. Discover the features and benefits of turning your data into stunning, interactive maps."
        openGraph={{
          title:
            'Mapstack Pricing: Find the Perfect Plan for Your Interactive Mapping Needs',
          description:
            "Explore Mapstack's pricing plans tailored for individuals, teams, and enterprises. Discover how you can turn data into interactive maps with ease.",
          type: 'website',
          images: [
            {
              url: 'https://mapstack.io/images/og_image_pricing.webp',
              secureUrl: 'https://mapstack.io/images/og_image_pricing.webp',
              alt: 'Mapstack Pricing - Affordable Plans for Interactive Mapping',
              type: 'image/webp',
              width: 1200,
              height: 630
            }
          ],
          url: 'https://mapstack.io/pricing',
          siteName: 'Mapstack'
        }}
      />

      <Layout slots={{ Header }} />
    </>
  )
}
